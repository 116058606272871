import { Box, Container, IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { ReactComponent as Logo } from "../../assets/jemix_Logo.svg";

import { Close, Menu } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { RootState } from "../../app/store";
import "./header.scss";

const baseURL = process.env.REACT_APP_BACKEND_URL;
export const Header = () => {
  const [menu, setMenu] = useState(false);

  const currentLanguage = useSelector(
    (state: RootState) => state.language.language
  );
  const [navbarContent, setNavbarContent] = useState([]);

  const fetchNavbarContent = async () => {
    try {
      let data: any = await fetch(
        `${baseURL}/navbars?${
          currentLanguage === "Deutsch"
            ? "locale=de"
            : currentLanguage === "Spanish"
            ? "locale=es"
            : "locale=en"
        }`
      );
      let response = await data.json();
      setNavbarContent(response?.data);
      return response;
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchNavbarContent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentLanguage]);

  return (
    <Box className={menu ? "mainHeader activeMenu" : "mainHeader"}>
      <Container maxWidth="lg">
        <Box className="mainHeader__inner">
          <Box className="mainHeader__logo">
            <Link to="/">
              <Logo />
            </Link>
          </Box>

          <IconButton className="menuToggleBtn" onClick={() => setMenu(!menu)}>
            {menu ? <Close /> : <Menu />}
          </IconButton>

          <Box className="mainHeader__menu">
            {navbarContent?.map((el: any, index) => {
              return (
                <React.Fragment key={index}>
                  <a
                    href={`#${el?.attributes?.LinkName}`}
                    className="mainHeader__menu-item"
                  >
                    {el?.attributes?.LinkName}
                  </a>
                </React.Fragment>
              );
            })}
          </Box>
        </Box>
      </Container>
    </Box>
  );
};
