import { Close } from '@mui/icons-material';
import { Button, Drawer, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import Cookies from "js-cookie";
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Hero } from '../components/Landing-page/Hero';
import { MidSection } from '../components/Landing-page/MidSection';
import { TopCards } from '../components/Landing-page/TopCards';
import { setCurrentLanguage } from '../features/language/languageSlice';

export const LandingPage = () => {
  const [open, setOpen] = useState<boolean>(false); // Start as closed
  const [selectedCountry, setSelectedCountry] = useState("English");
  const dispatch = useDispatch();

  useEffect(() => {
    // Check localStorage first
    const storedCountry = localStorage.getItem("country");
    if (storedCountry) {
      setSelectedCountry(storedCountry);
      dispatch(setCurrentLanguage(storedCountry));
    } else {
      // If not in localStorage, check cookies
      const country = Cookies.get("country");
      if (country) {
        setSelectedCountry(country);
        dispatch(setCurrentLanguage(country));
      } else {
        setOpen(true);
      }
    }
  }, [dispatch]);

  const handleCountryChange = (event: SelectChangeEvent<string>) => {
    const newCountry = event.target.value as string;
    setSelectedCountry(newCountry);
    dispatch(setCurrentLanguage(newCountry));
  };

  const handleContinue = () => {
    // Store in localStorage and cookies
    localStorage.setItem("country", selectedCountry);
    Cookies.set("country", selectedCountry, { expires: 9000 });
    setOpen(false);
  };

  return (
    <>
      <Drawer anchor="top" open={open}>
        <div className="DrawerTop">
          <p>
            Choose another language to see content specific to your preference
          </p>
          <div className="DrawerTop__btnBox">
            <Select
              className="CustomSelect"
              value={selectedCountry}
              onChange={handleCountryChange}
            >
              <MenuItem value="English">English</MenuItem>
              <MenuItem value="Deutsch">Deutsch</MenuItem>
              <MenuItem value="Spanish">Spanish</MenuItem>
            </Select>
            <Button
              className="nextBtn"
              variant="contained"
              color="primary"
              onClick={handleContinue}
            >
              Continue
            </Button>
            <Close className="closeBtn" onClick={() => setOpen(false)} />
          </div>
        </div>
      </Drawer>
      <Hero />
      <TopCards />
      <MidSection />
    </>
  );
};
