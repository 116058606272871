import {
  Box,
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";

import { Facebook, Instagram, Language, LinkedIn } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import { RootState } from "../app/store";
import mainLogo from "../assets/whiteLogoJemix.svg";
import { setCurrentLanguage } from "../features/language/languageSlice";
import "./footer.scss";

const baseURL = process.env.REACT_APP_BACKEND_URL;

export const Footer = () => {
  const [footerContent, setFooterContent] = useState<any>({});
  const dispatch = useDispatch();
  const currentLanguage = useSelector(
    (state: RootState) => state.language.language
  );

  const fetchFooterContent = async () => {
    try {
      if (currentLanguage === "Deutsch") {
        let data: any = await fetch(
          `${baseURL}/footer-content?locale=de`
        );
        let response = await data.json();
        setFooterContent(response?.data?.attributes);
        return response;
      } else if (currentLanguage === "Spanish") {
        let data: any = await fetch(
          `${baseURL}/footer-content?locale=es`
        );
        let response = await data.json();
        setFooterContent(response?.data?.attributes);
        return response;
      } else {
        let data: any = await fetch(
          `${baseURL}/footer-content`
        );
        let response = await data.json();
        setFooterContent(response?.data?.attributes);
        return response;
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleChange = (event: SelectChangeEvent) => {
    dispatch(setCurrentLanguage(event.target.value));
  };
  useEffect(() => {
    fetchFooterContent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentLanguage]);

  return (
    <Box className="siteFooter" id={`JEMIX`}>
      <Container maxWidth="lg">
        <Box className="siteFooter__top">
          <Box className="siteFooter__top-box">
            <FormControl
              fullWidth
              className="selectLang"
              sx={{ display: "flex", alignItems: "center" }}
            >
              <InputLabel id="demo-simple-select-label" sx={{ color: "gray" }}>
                Sprache
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={currentLanguage}
                onChange={handleChange}
                displayEmpty
                renderValue={(selected) => (
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Language sx={{ marginRight: "8px", color: "gray" }} />
                    <Typography>{selected || "English"}</Typography>
                  </Box>
                )}
                sx={{ minWidth: 150 }}
              >
                <MenuItem value="English">English</MenuItem>
                <MenuItem value="Deutsch">Deutsch</MenuItem>
                <MenuItem value="Spanish">Spanish</MenuItem>
              </Select>
            </FormControl>
            <Box className="siteFooter__socialLink">
              <Link to="https://www.instagram.com/jemix_gmbh/">
                <Instagram />
              </Link>
              <Link to="https://de.linkedin.com/company/jemix-gmbh">
                <LinkedIn />
              </Link>
              <Link to="https://www.facebook.com/jemixgmbh/">
                <Facebook />
              </Link>
            </Box>
          </Box>
          <Box className="siteFooter__logo">
            <img src={mainLogo} alt="logo" className="img-fluid" />
          </Box>
          <Box className="siteFooter__menu">
            <NavLink to="/imprints" className="siteFooter__menu-item">
              {footerContent?.LinkHeader1}
            </NavLink>
            <NavLink to="/privacy-policy" className="siteFooter__menu-item">
              {footerContent?.LinkHeader2}
            </NavLink>
            <NavLink to="/KONTAKT" className="siteFooter__menu-item">
              {footerContent?.LinkHeader3}
            </NavLink>
          </Box>
        </Box>
        <Box className="siteFooter__bottom">
          <Typography>{footerContent?.ParagraphHeader}</Typography>
          <Typography>{footerContent?.SubHeaderParagraph}</Typography>
        </Box>
      </Container>
    </Box>
  );
};
