import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../app/store";
import cottage from "../assets/home.svg";
import localHotel from "../assets/bed.svg";
import { Swiper, SwiperSlide } from "swiper/react";
import { Box, Link, Typography } from "@mui/material";
import desktopWindows from "../assets/DesktopWindows.svg";
import localHospital from "../assets/LocalHospital.svg";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import "swiper/css";
const baseURL = process.env.REACT_APP_BACKEND_URL;

export const TestimonialsSlider = () => {
  const [testimonialSliderItems, setTestimonialSliderItems] = useState<any>([]);
  const currentLanguage = useSelector(
    (state: RootState) => state.language.language
  );

  useEffect(() => {
    fetchTestimonialSlider();
  }, [currentLanguage]);

  const fetchTestimonialSlider = async () => {
    try {
      const localeParam = currentLanguage === "Deutsch" ? "de" :
                          currentLanguage === "Spanish" ? "es" :
                          "en"; 
      const response = await fetch(`${baseURL}/testimonial-sliders?locale=${localeParam}`);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      setTestimonialSliderItems(data.data);
    } catch (err) {
      console.error("Failed to fetch testimonial slider:", err);
    }
  };



  const arr3: any = [
    {
      partnerIcon: localHotel,
    },
    {
      partnerIcon: desktopWindows,
    },
    {
      partnerIcon: localHospital,
    },
    {
      partnerIcon: cottage,
    },
    {
      partnerIcon: cottage,
    },
  ];

  return (
    <Swiper className="w-100"
      grabCursor={true}
      // centeredSlides={true}
      autoplay={{
        delay: 2500,
        disableOnInteraction: false,
      }}
      slidesPerView={4}
      loop={true}
      slidesPerGroup={1}
      modules={[Autoplay, Navigation, Pagination]}
      breakpoints={{
        250: {
          slidesPerView: 1,
          spaceBetween: 10,
        },
        575: {
          slidesPerView: 2,
          spaceBetween: 10,
        },
        991: {
          slidesPerView: 2,
          spaceBetween: 10,
        },
        1024: {
          slidesPerView: 4,
          spaceBetween: 30,
        },
      }}
    >
      {testimonialSliderItems?.map(
        (testimonialSliderItem: any, index: number) => (
          <SwiperSlide key={index}>
            <Link
              href={testimonialSliderItem.attributes.PartnerLink}
              className="iconCard"
            >
              <Box className="iconCard__img">
                <img
                  src={arr3[index].partnerIcon}
                  alt="icon"
                  className="img-fluid"
                />
              </Box>
              <Typography className="iconCard__title">
                {testimonialSliderItem.attributes.PartnerTitle}
              </Typography>
            </Link>
          </SwiperSlide>
        )
      )}
    </Swiper>
  );
};
