import { Box, Button, Container, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../app/store";
import hero from "../../assets/hero-svg.png";
import { Header } from "../Header/Header";
import "./hero.scss";

interface MainContentTypes {
  TopText?: string;
  MainText?: string;
  BottomText?: string;
  Button1?: string;
  Button2?: string;
}

const baseURL = process.env.REACT_APP_BACKEND_URL;

export const Hero = () => {
  const [mainContent, setMainContent] = useState<MainContentTypes>({});
  const currentLanguage = useSelector(
    (state: RootState) => state.language.language
  );

  useEffect(() => {
    fetchMainContent();
  }, [currentLanguage]);

  const fetchMainContent = async () => {
    try {
      let data = await fetch(
        `${baseURL}/main-content?${
          currentLanguage === "Deutsch"
            ? "locale=de"
            : currentLanguage === "Spanish"
            ? "locale=es" 
            : "locale=en"
        }`
      );

      if (!data.ok) {
        throw new Error(`HTTP error! status: ${data.status}`);
      }

      let response = await data.json();
      setMainContent(response.data.attributes);
    } catch (err) {
      console.error("Failed to fetch main content:", err);
    }
  };

  return (
    <Box className="hero-header">
      <Header />
      <Box className="heroModule">
        <Container maxWidth="lg">
          <Grid container>
            <Grid item xs={12} lg={8}>
              <Box className="heroModule__content">
                <Typography className="heroModule__subtitle">
                  {mainContent.TopText}
                </Typography>
                <Typography className="heroModule__title">
                  {mainContent.MainText?.split(" ")
                    .slice(0, -1)  
                    .join(" ")}
                  <span>
                    {" "}
                    {mainContent.MainText?.split(" ").slice(-1)} 
                  </span>
                </Typography>
                <Typography className="heroModule__text">
                  {mainContent.BottomText}
                </Typography>
                <Box className="heroModule__buttons">
                  <Button className="primaryBtn" href="#JEMIX">
                    {mainContent.Button1}
                  </Button>
                  <Button
                    className="primaryBtn primaryBtn--border"
                    href={`#${mainContent.Button2}`}
                  >
                    {mainContent.Button2}
                  </Button>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} lg={4}>
              <Box className="heroModule__img"></Box>
            </Grid>
          </Grid>
        </Container>
        <Box className="heroModule__img">
          <img src={hero} alt="heroImage" className="img-fluid w-100" />
        </Box>
      </Box>
    </Box>
  );
};
