import {
  Box,
  Button,
  CardContent,
  Container,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";

import agencies from "../../assets/Agencies.png";
import callicon from "../../assets/callicon.svg";
import hotel from "../../assets/hotel-svg.png";
import IphoneHand from "../../assets/iphoneHand.png";
import systemhaus from "../../assets/IT-Systemhaus.svg";
import praxen from "../../assets/praxenNew.png";
import privat from "../../assets/Privat.png";
import SliderLeftShape from "../../assets/SliderLeftShape.png";
import SliderRating from "../../assets/SliderRating.png";
import SliderRightShape from "../../assets/SliderRightShape.png";
import smallPattern from "../../assets/smallShadow.svg";
import User from "../../assets/user.png";
// import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
// import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector } from "react-redux";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { RootState } from "../../app/store";
import { TestimonialsSlider } from "../TestimonialsSlider";
import "./midSection.scss";

const baseURL = process.env.REACT_APP_BACKEND_URL;
const images = [
  {
    image: hotel,
  },
  {
    image: agencies,
  },
  {
    image: praxen,
  },
  {
    image: privat,
  },
];

export const MidSection = () => {
  const currentLanguage = useSelector(
    (state: RootState) => state.language.language
  );
  const sliderRef = useRef<any>();
  const [activeSlide, setActiveSlide] = useState(0);
  const [sliderCardContent, setSliderCardContent] = useState<any>([]);
  const [landingPageMainContent, setLandingPageMainContent] = useState<any>({});
  const [landingPageStats, setLandingPageStats] = useState<any>([]);
  const [partnerBlockText, setPartnerBlockText] = useState<any>({});
  const [serviceListData, setServiceListData] = useState<any>([]);
  const [customerSliderLeftText, setCustomerSliderLeftText] = useState<any>({});
  const [phoneContent, setPhoneContent] = useState<any>({});
  const [formVisible, setFormVisible] = useState(false);
  const [isInputFocused, setIsInputFocused] = useState(false);

  const [formData, setFormData] = useState({
    name: "",
    business: "",
    phoneNumber: "",
    email: "",
    news: "",
  });

  const handleFormSubmit = (event: any) => {
    event.preventDefault();
    setFormVisible(!formVisible);
    // You can add logic here to save the form data to your database or perform other actions
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // const handlePrev = useCallback(() => {
  //   if (!sliderRef.current) return;
  //   sliderRef.current.swiper.slidePrev();
  // }, []);

  // const handleNext = useCallback(() => {
  //   if (!sliderRef.current) return;
  //   sliderRef.current.swiper.slideNext();
  // }, []);

  const fetchSliderCardContent = async () => {
    try {
      if (currentLanguage === "Deutsch") {
        let data: any = await fetch(`${baseURL}/customer-sliders?locale=de`);
        let response = await data.json();
        setSliderCardContent(response.data);
        return response;
      } else if (currentLanguage === "Spanish") {
        let data: any = await fetch(`${baseURL}/customer-sliders?locale=es`);
        let response = await data.json();
        setSliderCardContent(response.data);
        return response;
      } else {
        let data: any = await fetch(`${baseURL}/customer-sliders`);
        let response = await data.json();
        setSliderCardContent(response.data);
        return response;
      }
    } catch (err) {
      console.log(err);
    }
  };

  const fetchLandingPageMainContent = async () => {
    try {
      if (currentLanguage === "Deutsch") {
        let data: any = await fetch(
          `${baseURL}/landing-page-main-header?locale=de`
        );
        let response = await data.json();
        setLandingPageMainContent(response.data.attributes);
        return response;
      } else if (currentLanguage === "Spanish") {
        let data: any = await fetch(
          `${baseURL}/landing-page-main-header?locale=es`
        );
        let response = await data.json();
        setLandingPageMainContent(response.data.attributes);
        return response;
      } else {
        let data: any = await fetch(`${baseURL}/landing-page-main-header`);
        let response = await data.json();
        setLandingPageMainContent(response.data.attributes);
        return response;
      }
    } catch (err) {
      console.log(err);
    }
  };

  const fetchLandingPageStats = async () => {
    try {
      if (currentLanguage === "Deutsch") {
        let data: any = await fetch(
          `${baseURL}/landing-page-stat-cards?locale=de`
        );
        let response = await data.json();
        setLandingPageStats(response.data);
        return response;
      } else if (currentLanguage === "Spanish") {
        let data: any = await fetch(
          `${baseURL}/landing-page-stat-cards?locale=es`
        );
        let response = await data.json();
        setLandingPageStats(response.data);
        return response;
      } else {
        let data: any = await fetch(`${baseURL}/landing-page-stat-cards`);
        let response = await data.json();
        setLandingPageStats(response.data);
        return response;
      }
    } catch (err) {
      console.log(err);
    }
  };

  const fetchProjectBlocktext = async () => {
    try {
      if (currentLanguage === "Deutsch") {
        let data: any = await fetch(`${baseURL}/partner-block-text?locale=de`);
        let response = await data.json();
        setPartnerBlockText(response.data.attributes);
        return response;
      } else if (currentLanguage === "Spanish") {
        let data: any = await fetch(`${baseURL}/partner-block-text?locale=es`);
        let response = await data.json();
        setPartnerBlockText(response.data.attributes);
        return response;
      } else {
        let data: any = await fetch(`${baseURL}/partner-block-text`);
        let response = await data.json();
        setPartnerBlockText(response.data.attributes);
        return response;
      }
    } catch (err) {
      console.log(err);
    }
  };

  const fetchServiceLists = async () => {
    try {
      if (currentLanguage === "Deutsch") {
        let data: any = await fetch(`${baseURL}/service-lists?locale=de`);
        let response = await data.json();
        setServiceListData(response.data);
        return response;
      } else if (currentLanguage === "Spanish") {
        let data: any = await fetch(`${baseURL}/service-lists?locale=es`);
        let response = await data.json();
        setServiceListData(response.data);
        return response;
      } else {
        let data: any = await fetch(`${baseURL}/service-lists`);
        let response = await data.json();
        setServiceListData(response.data);
        return response;
      }
    } catch (err) {
      console.log(err);
    }
  };

  const fetchCustomerSliderText = async () => {
    try {
      if (currentLanguage === "Deutsch") {
        let data: any = await fetch(
          `${baseURL}/customer-slider-left-text?locale=de`
        );
        let response = await data.json();
        setCustomerSliderLeftText(response.data.attributes);
        return response;
      } else if (currentLanguage === "Spanish") {
        let data: any = await fetch(
          `${baseURL}/customer-slider-left-text?locale=es`
        );
        let response = await data.json();
        setCustomerSliderLeftText(response.data.attributes);
        return response;
      } else {
        let data: any = await fetch(`${baseURL}/customer-slider-left-text`);
        let response = await data.json();
        setCustomerSliderLeftText(response.data.attributes);
        return response;
      }
    } catch (err) {
      console.log(err);
    }
  };

  const fetchMobilePhoneContent = async () => {
    try {
      if (currentLanguage === "Deutsch") {
        let data: any = await fetch(`${baseURL}/mobile-phonecontent?locale=de`);
        let response = await data.json();
        setPhoneContent(response?.data?.attributes);
        return response;
      } else if (currentLanguage === "Spanish") {
        let data: any = await fetch(`${baseURL}/mobile-phonecontent?locale=es`);
        let response = await data.json();
        setPhoneContent(response?.data?.attributes);
        return response;
      } else {
        let data: any = await fetch(`${baseURL}/mobile-phonecontent`);
        let response = await data.json();
        setPhoneContent(response?.data?.attributes);
        return response;
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchSliderCardContent();
    fetchLandingPageMainContent();
    fetchLandingPageStats();
    fetchProjectBlocktext();
    fetchServiceLists();
    fetchCustomerSliderText();
    fetchMobilePhoneContent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentLanguage]);

  const handleSlideChange = () => {
    const activeIndex = sliderRef.current.swiper.activeIndex;
    setActiveSlide(activeIndex);
  };

  return (
    <Box>
      <Box className="mediaContentModule" id="JEMIX">
        <Container maxWidth="xl">
          <Box className="sectionHeading">
            <Typography className="sectionHeading__title">
              {landingPageMainContent?.MainHeading}
            </Typography>
            <Typography className="sectionHeading__subTitle">
              {landingPageMainContent?.SubHeading}
            </Typography>
          </Box>

          <Grid container>
            <Grid item lg={6} xs={12}>
              <Box className="mediaContentModule__img">
                <img src={systemhaus} alt={"hero"} width="100%" height="100%" />
                <img
                  src={smallPattern}
                  alt={"hero"}
                  width="100%"
                  height="100%"
                  className="patternImg"
                />
              </Box>
            </Grid>
            <Grid item lg={6} xs={12}>
              <Box className="mediaContentModule__content">
                <Typography className="mediaContentModule__text">
                  {landingPageMainContent?.Description1}
                  <br /> <br />
                  {landingPageMainContent?.Description2}
                </Typography>
                <Grid container spacing={2} mt={"25px"}>
                  {landingPageStats.map((landingPageStat: any, index: any) => (
                    <Grid key={index} item lg={4} xs={12}>
                      <Box className="statusCard">
                        <Typography variant="h5" className="statusCard__title">
                          {landingPageStat.attributes.MainText}
                        </Typography>
                        <Typography className="statusCard__text">
                          {landingPageStat.attributes.SubText}
                        </Typography>
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box className="partnerBlock">
        <Container maxWidth="xl">
          <Box
            className="sectionHeading"
            id={currentLanguage === "English" ? "SERVICES" : "LEISTUNGEN"}
          >
            <Typography className="sectionHeading__title">
              {partnerBlockText?.MainText}
            </Typography>
            <Typography className="sectionHeading__subTitle">
              {partnerBlockText?.SubText}
            </Typography>
          </Box>
          <Grid container spacing={3}>
            <TestimonialsSlider />
          </Grid>
        </Container>
      </Box>
      <Box className="serviceList">
        {serviceListData ? (
          serviceListData?.map((serviceListItem: any, index: any) => {
            return (
              <Box
                className={
                  serviceListItem.attributes.TopHeading ===
                  "HOTEL & HOLIDAY HOMES"
                    ? "mediaContentBlock mediaContentBlock--pattern imageShape hotelBlock"
                    : serviceListItem.attributes.TopHeading ===
                      "Hotel & Ferienhäuser"
                    ? "mediaContentBlock mediaContentBlock--pattern imageShape hotelBlock"
                    : serviceListItem.attributes.TopHeading ===
                      "HOTELES Y CASAS DE VACACIONES"
                    ? "mediaContentBlock mediaContentBlock--pattern imageShape hotelBlock"
                    : serviceListItem.attributes.TopHeading ===
                        "Büros & Agenturen" ||
                      serviceListItem.attributes.TopHeading ===
                        "Offices & Agencies" ||
                      serviceListItem.attributes.TopHeading ===
                        "Oficinas y Agencias"
                    ? "mediaContentBlock imageShape burosBlock"
                    : serviceListItem.attributes.TopHeading ===
                        "Praxen & Ärzte" ||
                      serviceListItem.attributes.TopHeading ===
                        "Practices & Doctors" ||
                      serviceListItem.attributes.TopHeading ===
                        "PRAXEN & ÄRZTE" ||
                      serviceListItem.attributes.TopHeading ===
                        "Prácticas y médicos"
                    ? "mediaContentBlock imageShape praxenBlock"
                    : serviceListItem.attributes.TopHeading ===
                        "FINCA Y TI PRIVADA" ||
                      serviceListItem.attributes.TopHeading ===
                        "FINCA & PRIVATE IT" ||
                      serviceListItem.attributes.TopHeading ===
                        "FINCA & PRIVAT IT"
                    ? "mediaContentBlock imageShape fincaBlock"
                    : ""
                }
                id={
                  serviceListItem.attributes.TopHeading ===
                  "HOTEL & HOLIDAY HOMES"
                    ? "hotelBlock"
                    : serviceListItem.attributes.TopHeading ===
                      "Hotel & Ferienhäuser "
                    ? "hotelBlock"
                    : serviceListItem.attributes.TopHeading ===
                      "Hoteles y casas de vacaciones"
                    ? "hotelBlock"
                    : serviceListItem.attributes.TopHeading ===
                        "Büros & Agenturen" ||
                      serviceListItem.attributes.TopHeading ===
                        "Offices & Agencies"
                    ? "burosBlock"
                    : serviceListItem.attributes.TopHeading ===
                      "Oficinas y agencias"
                    ? "burosBlock"
                    : serviceListItem.attributes.TopHeading === "PRAXEN & ÄRZTE"
                    ? "praxenBlock"
                    : serviceListItem.attributes.TopHeading ===
                      "Practices & Doctors"
                    ? "praxenBlock"
                    : serviceListItem.attributes.TopHeading ===
                      "PRÁCTICAS Y DOCTORES"
                    ? "praxenBlock"
                    : serviceListItem.attributes.TopHeading ===
                        "FINCA & PRIVAT IT" ||
                      serviceListItem.attributes.TopHeading ===
                        "FINCA & PRIVATE IT"
                    ? "fincaBlock"
                    : ""
                }
                key={index}
              >
                <Container maxWidth="xl">
                  <Grid container alignItems={"center"} spacing={3}>
                    <Grid item md={6} xs={12} className="">
                      <Box className="mediaContentBlock__img">
                        <img
                          src={images[index].image}
                          alt={"hero"}
                          width="100%"
                        />
                      </Box>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <Box className="mediaContentBlock__content">
                        <Typography className="mediaContentBlock__subTitle">
                          {serviceListItem.attributes.TopHeading}
                        </Typography>
                        <Typography
                          variant="h4"
                          className="mediaContentBlock__title"
                        >
                          {serviceListItem.attributes.BottomHeading}
                        </Typography>
                        <Typography className="mediaContentBlock__text">
                          {serviceListItem.attributes.Description}
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Container>
              </Box>
            );
          })
        ) : (
          <>
            <Box
              className="mediaContentBlock mediaContentBlock--pattern imageShape hotelBlock"
              id="hotelBlock"
            >
              <Container maxWidth="xl">
                <Grid container alignItems={"center"} spacing={3}>
                  <Grid item md={6} xs={12} className="">
                    <Box className="mediaContentBlock__img">
                      <img src={hotel} alt={"hero"} width="100%" />
                    </Box>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Box className="mediaContentBlock__content">
                      <Typography className="mediaContentBlock__subTitle">
                        HOTEL
                      </Typography>
                      <Typography
                        variant="h4"
                        className="mediaContentBlock__title"
                      >
                        High-Performance WLAN
                      </Typography>
                      <Typography className="mediaContentBlock__text">
                        Bei WLAN geht es um Stabilität, Bandbreite und
                        Abdeckung. Mit unserer Erfahrung von anspruchsvollen
                        Privathaushalten bis zu komplexen Büroumgebungen sind
                        wir in der Lage, Ihnen ein entsprechendes
                        High-Performance WLAN einzurichten.
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Container>
            </Box>
            <Box
              className="mediaContentBlock imageShape burosBlock"
              id="burosBlock"
            >
              <Container maxWidth="xl">
                <Grid container alignItems={"center"} spacing={3}>
                  <Grid item md={6} xs={12}>
                    <Box className="mediaContentBlock__img">
                      <img src={agencies} alt={"hero"} width="100%" />
                    </Box>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Box className="mediaContentBlock__content">
                      <Typography className="mediaContentBlock__subTitle">
                        Büros & Agenturen
                      </Typography>
                      <Typography
                        variant="h4"
                        className="mediaContentBlock__title"
                      >
                        High-Performance WLAN
                      </Typography>
                      <Typography className="mediaContentBlock__text">
                        Bei WLAN geht es um Stabilität, Bandbreite und
                        Abdeckung. Mit unserer Erfahrung von anspruchsvollen
                        Privathaushalten bis zu komplexen Büroumgebungen sind
                        wir in der Lage, Ihnen ein entsprechendes
                        High-Performance WLAN einzurichten.
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Container>
            </Box>
            <Box
              className="mediaContentBlock imageShape praxenBlock"
              id="praxenBlock"
            >
              <Container maxWidth="xl">
                <Grid container alignItems={"center"} spacing={3}>
                  <Grid item md={6} xs={12}>
                    <Box className="mediaContentBlock__img">
                      <img src={praxen} alt={"hero"} width="100%" />
                    </Box>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Box className="mediaContentBlock__content">
                      <Typography className="mediaContentBlock__subTitle">
                        Praxen & Ärzte
                      </Typography>
                      <Typography
                        variant="h4"
                        className="mediaContentBlock__title"
                      >
                        High-Performance WLAN
                      </Typography>
                      <Typography className="mediaContentBlock__text">
                        Bei WLAN geht es um Stabilität, Bandbreite und
                        Abdeckung. Mit unserer Erfahrung von anspruchsvollen
                        Privathaushalten bis zu komplexen Büroumgebungen sind
                        wir in der Lage, Ihnen ein entsprechendes
                        High-Performance WLAN einzurichten.
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Container>
            </Box>
            <Box
              className="mediaContentBlock imageShape fincaBlock"
              id="fincaBlock"
            >
              <Container maxWidth="xl">
                <Grid container alignItems={"center"} spacing={3}>
                  <Grid item md={6} xs={12}>
                    <img className="rightGreen" src={hotel} alt={"hero"} />
                    <Box className="mediaContentBlock__img">
                      <img src={privat} alt={"hero"} width="100%" />
                    </Box>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Box className="mediaContentBlock__content">
                      <Typography className="mediaContentBlock__subTitle">
                        Finca & Privat IT
                      </Typography>
                      <Typography
                        variant="h4"
                        className="mediaContentBlock__title"
                      >
                        High-Performance WLAN
                      </Typography>
                      <Typography className="mediaContentBlock__text">
                        Bei WLAN geht es um Stabilität, Bandbreite und
                        Abdeckung. Mit unserer Erfahrung von anspruchsvollen
                        Privathaushalten bis zu komplexen Büroumgebungen sind
                        wir in der Lage, Ihnen ein entsprechendes
                        High-Performance WLAN einzurichten.
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Container>
            </Box>
          </>
        )}
      </Box>
      <Box
        className="intrestPartSection"
        sx={{
          display: "flex",
          width: "100%",
          bgcolor: "#222222",
          flexDirection: "row",
        }}
        id={currentLanguage === "English" ? "CONTACT" : "KONTAKT"}
      >
        <Container maxWidth="xl" style={{ position: "relative" }}>
          {formVisible ? (
            <CloseIcon
              className="CrossBtn"
              onClick={() => setFormVisible(false)}
            />
          ) : null}
          <Grid container alignItems={"center"} spacing={3}>
            <Grid item md={7} xs={12}>
              <Box className="intrestPartSection__info">
                <Box className="sectionHeading">
                  <Typography className="sectionHeading__title">
                    {phoneContent?.Heading}
                  </Typography>
                  <Typography className="sectionHeading__subTitle">
                    {phoneContent?.SubHeading}
                  </Typography>
                </Box>

                <Box className="intrestPartSection__content">
                  <Typography className="intrestPartSection__title">
                    <span style={{ fontWeight: "700" }}>
                      {phoneContent?.Paragraphcontent}
                    </span>
                  </Typography>
                  <Typography className="intrestPartSection__text">
                    {phoneContent?.SubParagraphContent}
                  </Typography>
                </Box>
                {formVisible ? (
                  <form
                    className="intrestPartSection__form"
                    onSubmit={handleFormSubmit}
                  >
                    <Grid container spacing={3}>
                      <Grid item md={7} xs={12}>
                        <TextField
                          autoComplete={"off"}
                          className="textBlock"
                          id="name"
                          name="name"
                          label={
                            currentLanguage === "Deutsch"
                              ? "NAME *"
                              : currentLanguage === "Spanish"
                              ? "NOMBRE *"
                              : "NAME *"
                          }
                          variant="outlined"
                          fullWidth
                          margin="normal"
                          value={formData.name}
                          onChange={handleInputChange}
                          InputLabelProps={{
                            shrink: true,
                            focused: true,
                          }}
                        />
                      </Grid>
                      <Grid item md={5} xs={12}>
                        <TextField
                          autoComplete={"off"}
                          className="textBlock"
                          id="business"
                          name="business"
                          label={
                            currentLanguage === "Deutsch"
                              ? "FIRMA *"
                              : currentLanguage === "Spanish"
                              ? "NEGOCIO *"
                              : "BUSINESS *"
                          }
                          variant="outlined"
                          fullWidth
                          margin="normal"
                          value={formData.business}
                          onChange={handleInputChange}
                          InputLabelProps={{
                            shrink: true,
                            focused: true,
                          }}
                        />
                      </Grid>
                      <Grid item md={7} xs={12}>
                        <TextField
                          autoComplete={"off"}
                          className="textBlock"
                          id="phone"
                          name="phoneNumber"
                          label={
                            currentLanguage === "Deutsch"
                              ? "RUFNUMMER *"
                              : currentLanguage === "Spanish"
                              ? "NUMERO DE TELEFONO"
                              : "PHONE NUMBER *"
                          }
                          variant="outlined"
                          fullWidth
                          margin="normal"
                          value={formData.phoneNumber}
                          onChange={handleInputChange}
                          InputLabelProps={{
                            shrink: true,
                            focused: true,
                          }}
                        />
                      </Grid>
                      <Grid item md={5} xs={12}>
                        <TextField
                          autoComplete="new-email"
                          className="textBlock"
                          id="email"
                          name="email"
                          label={
                            currentLanguage === "Deutsch"
                              ? "EMAIL *"
                              : currentLanguage === "Spanish"
                              ? "CORREO ELECTRÓNICO *"
                              : "EMAIL *"
                          }
                          variant="outlined"
                          fullWidth
                          margin="normal"
                          value={formData.email}
                          onChange={handleInputChange}
                          InputLabelProps={{
                            shrink: true,
                            focused: true,
                          }}
                        />
                      </Grid>
                      <Grid item md={12} xs={12}>
                        <TextField
                          autoComplete={"off"}
                          className="textBlock"
                          id="news"
                          name="news"
                          label={
                            currentLanguage === "Deutsch"
                              ? "NACHRICHT *"
                              : currentLanguage === "Spanish"
                              ? "NOTICIAS *"
                              : "NEWS *"
                          }
                          variant="outlined"
                          fullWidth
                          multiline
                          rows={4}
                          margin="normal"
                          value={formData.news}
                          onChange={handleInputChange}
                          InputLabelProps={{
                            shrink: true,
                            focused: true,
                          }}
                        />
                        <Button className="btn" type="submit">
                          Submit
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                ) : (
                  <div className="contactBottomPart">
                    <a className="call" href="tel:+34 871 18 04 22">
                      <img src={callicon} alt="" />
                      +34 871 18 04 22
                    </a>
                    <Button
                      className="btn"
                      onClick={() => setFormVisible(!formVisible)}
                    >
                      {phoneContent?.ButtonConntent}
                    </Button>
                  </div>
                )}
              </Box>
            </Grid>
            <Grid item md={5} xs={12}>
              <Box className="imagePhone">
                <img width={"100%"} src={IphoneHand} alt={"hero"} />
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box className="Slider">
        <Box className="Slider__leftPart">
          <img className="SliderLeftShape" src={SliderLeftShape} alt="" />
          <Box className="Slider_title">
            <b>“</b>
            <span>{customerSliderLeftText.MainText}</span>
            {/* <Box className="Slider__Btn">
              <button
                className="arrowBtn leftArrow"
                onClick={handlePrev}
                style={{ zIndex: "1000" }}
              >
                <ArrowBackIosNewIcon />
              </button>
              <button
                className="arrowBtn rightArrow"
                onClick={handleNext}
                style={{ zIndex: "1000" }}
              >
                <ArrowForwardIosIcon />
              </button>
            </Box> */}
          </Box>
        </Box>
        <Box className="Slider__rightPart sliderCard">
          <img className="SliderRightShape" src={SliderRightShape} alt="" />
          <Swiper
            ref={sliderRef}
            modules={[Navigation, Pagination]}
            navigation={true}
            className="mySwiper"
            grabCursor={true}
            centeredSlides={true}
            slidesPerView={1.5}
            slidesPerGroup={1}
            onSlideChange={handleSlideChange}
            pagination={{ clickable: true }}
            breakpoints={{
              250: {
                slidesPerView: 1.1,
                spaceBetween: 10,
              },
              575: {
                slidesPerView: 1.5,
                spaceBetween: 10,
              },
              991: {
                slidesPerView: 1.7,
                spaceBetween: 10,
              },
              1024: {
                slidesPerView: 1.9,
                spaceBetween: 30,
              },
            }}
          >
            {sliderCardContent.map((sliderCard: any, index: number) => (
              <SwiperSlide
                style={{ opacity: activeSlide === sliderCard.id ? 0 : 1 }}
                key={index}
              >
                <Box className="sliderCard__Box">
                  <CardContent className="sliderCard__Box__Content">
                    <Typography className="Title">
                      {sliderCard.attributes.Heading}
                    </Typography>
                    <img className="SliderRating" src={SliderRating} alt="" />
                    <p>{sliderCard.attributes.Description}</p>
                    <Box className="Userprofile">
                      <img className="Userprofile__image" src={User} alt="" />
                      <h4 className="Userprofile__name">
                        {sliderCard.attributes.CustomerName}
                      </h4>
                      <p className="Userprofile__details">
                        {sliderCard.attributes.CustomerDesignation}
                      </p>
                    </Box>
                  </CardContent>
                </Box>
              </SwiperSlide>
            ))}
          </Swiper>
        </Box>
      </Box>
    </Box>
  );
};
