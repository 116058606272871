import { Box, Container, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../app/store";
import approved from "../../assets/approved-icon.svg";
import experten from "../../assets/experten-icon.svg";
import security from "../../assets/security-icon.svg";
import "./topCards.scss";

type singleAttr = {
  CardHeading: string;
  CardDescription: string;
  icon?: string;
};

interface SingleCard {
  id: number;
  attributes: singleAttr;
}

const baseURL = process.env.REACT_APP_BACKEND_URL;
export const TopCards = () => {
  const icons: any = [
    {
      icon: security,
    },
    {
      icon: approved,
    },
    {
      icon: experten,
    },
  ];
  const [cardData, setCardData] = useState<SingleCard[]>([]);
  const currentLanguage = useSelector(
    (state: RootState) => state.language.language
  );
  const fetchCardContent = async () => {
    try {
      let data: any = await fetch(
        `${baseURL}/feature-cards?${
          currentLanguage === "English"
            ? "locale=en"
            : currentLanguage === "Spanish"
            ? "locale=es"
            : "locale=de"
        }`
      );
      let response = await data.json();
      setCardData(response.data);
      return response;
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchCardContent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentLanguage]);

  return (
    <>
      <Box className="threeColModule">
        <Container maxWidth="lg">
          <Box className="threeColModule__inner">
            {cardData?.map((singleCard, index) => (
              <Box key={index} className="darkCard">
                <Box className="darkCard__img">
                  <img
                    src={icons[index].icon}
                    alt="icon"
                    className="img-fluid"
                  />
                </Box>
                <Typography variant="h5" className="darkCard__title">
                  {singleCard.attributes.CardHeading}
                </Typography>
                <Typography className="darkCard__text">
                  {singleCard.attributes.CardDescription}
                </Typography>
              </Box>
            ))}
          </Box>
        </Container>
      </Box>
    </>
  );
};
